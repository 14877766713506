footer {
    min-height: 75px;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: 85%;
        margin: 0 auto;
        @extend .flex;
        @extend .flex-wrap;
        @extend .items-center;
        @extend .justify-between;
        @extend .text-color-grey;
        @extend .font-footer;
        .copyright {
            //font-size: 15px;
            //margin-bottom: 10px;
        }
        .mediaweb {
            //font-size: 15px;
            //margin-bottom: 10px;
            a {
                @extend .text-color-grey;
                @extend .flex;
                @extend .items-center;
                img {
                    margin: -7px 0 0 0;
                }
                span { padding-left: 5px;}
                &:hover {
                    @extend .text-color-contrast;
                }
            }
        }
        @media screen and (max-width: $size-sm-max) {
            width: 100%;
            padding: 0 5%;
            @media screen and (max-width: $size-xs-max) {
                flex-direction: column;
                .copyright {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
