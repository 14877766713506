.bursts {
    width: 100%;
    background: #303030;
    padding: 35px 0;
    .bursts-container {
        @extend .flex;
        .burst {
            //flex: 1;
            position: relative;
            .text-container, .overlay {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
            .text-container {
                padding: 55px 1.25rem;
                .border {
                    border: 1px solid $color-white;
                    width: 100%;
                    height: 100%;
                    @extend .flex;
                    @extend .flex-column;
                    @extend .justify-center;
                    @extend .items-center;
                    transition: all 0.2s ease-out; // OUT effect
                }
            }
            .overlay {
                opacity: 0;
                transition: all 0.7s ease-out; // OUT effect
            }
            h3, p {
                font-weight: normal;
                font-size: $font-size-30;
                color: $color-white;
                text-transform: initial;
                text-align: center;
                line-height: 1.1;
                transition: all 0.2s ease-out; // OUT effect
            }
            p {
                font-family: $font-family-1;
                text-transform: uppercase;
            }
            .btn-burst {
                background: #f19922;
                width: 235px;
                border-radius: 5px;
                margin: 15px auto 0 auto;
                height: 55px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 0.2s ease-out; // OUT effect
                h5 {
                    font-weight: normal;
                    font-size: $font-size-18;
                    color: $color-white;
                    text-transform: uppercase;
                }
            }
        }
        a:hover .burst {
            .overlay {
                opacity: 1;
                transition: all 0.3s ease; // IN effect
            }
            h3, p {
                color: #f19922;
                text-shadow: 0 0 10px $color-black;
                transition: all 0.3s ease; // IN effect
            }
            .btn-burst {
                background: #303030;
                transition: all 0.3s ease; // IN effect
                h5 {
                    color: #f19922;
                    transition: all 0.3s ease; // IN effect
                }
            }
            .border {
                //border-color: $color-secondary;
                transition: all 0.3s ease; // IN effect
            }
        }
    }
}
@media screen and (max-width: 1450px) {
    .bursts {
        .bursts-container {
            .burst {
                .text-container {
                    padding: 35px 1.25rem;
                    h3, p {
                        font-size: 1.3rem;
                    }
                    .btn-burst {
                        width: 200px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        .bursts {
            .bursts-container {
                flex-direction: column;
            }
        }
    }
    @media screen and (max-width: 1175px) {
        .bursts {
            .bursts-container {
                .burst {
                    .text-container {
                        padding: 1.25rem;
                    }
                }
            }
        }
        @media screen and (max-width: 1024px) {
            .bursts {
                .bursts-container {
                    .burst {
                        .text-container {
                            h3, p {
                                font-size: 1.15rem;
                            }
                            .btn-burst {
                                width: 150px;
                                height: 40px;
                            }
                        }
                    }
                }
            }
            @media screen and (max-width: $size-sm-max) {
                .bursts {
                    .bursts-container {
                        .burst {
                            .text-container {
                                padding: 13px 1.25rem;
                                .btn-burst {
                                    margin: 5px auto 0 auto;
                                }
                            }
                        }
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    .bursts {
                        .bursts-container {
                            .burst {
                                .text-container {
                                    padding: 40px 1.25rem;
                                    h3, p {
                                        font-size: 1.5rem;
                                    }
                                    .btn-burst {
                                        margin: 10px auto 0 auto;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
