.btn {
    background-color: #f19922;
    color: $color-white;
    border: none;
    border-radius: 5px;
    padding: 15px;
    font-weight: 500;
    font-size: $font-size-18;
    width: 230px;
    transition: all 0.2s ease-in;
    
    &:hover {
        transition: all 0.2s ease-in;
        color: $color-black;
    }
}
