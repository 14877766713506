// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $color-black;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

h1, h2, h3, h4, h5 {
    font-family: $font-family-1;
}

p {
    font-family: $font-family-2;
    font-size: $font-size-22;
    font-weight: normal;
    color: #c2c2c2;
    margin: 0;
    line-height: 1.3;
    text-align: justify;
}

/** Replace the width of the container **/
.container {
    width: 1550px;
    @media screen and (max-width: 1550px) {
        width: 100%;
        padding: 0 25px;
    }
}

.ls-gui-element.ls-bar-timer {
    display: none;
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';




/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-black {
    background: url("../images/accueil_section01_bg.jpg") no-repeat;
    background-repeat: repeat;
    background-size: auto;
    padding: 65px 0 0 0;
    .section-intro {
        h2 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #f19922;
            text-align: center;
            padding-bottom: 65px;
        }
    }
    .section-text-img {
        padding-bottom: 50px;
        .left-side {
            width: 50%;
            margin-right: 5%;
            h2 {
                text-align: initial;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .section-text-img {
            flex-direction: column;
            .left-side {
                width: 100%;
                margin: 0 0 35px 0;
            }
            .right-side {
                img.img-responsive {
                    margin: auto;
                }
            }
        }
        @media screen and (max-width: $size-xs-max) {
            .section-intro h2 {
                padding-bottom: 35px;
            }
        }
    }
}

#section-background {
    background: #f19922;
    padding: 25px 0;
    h3 {
        text-align: center;
        font-weight: bold;
        font-size: $font-size-30;
        color: $color-white;
        text-transform: initial;
    }
    @media screen and (max-width: $size-xs-max) {
        padding: 25px;
    }
}

#section-bloc-text {
    padding: 35px 0 50px 0;
    background: #303030;
    .left-side {
        width: 49%;
        margin-right: 5%;
        .bloc {
            h3 {
                font-weight: bold;
                font-size: $font-size-24;
                color: #f19922;
                text-transform: initial;
                padding-bottom: 35px;
            }
            .title {
                background: #d7d7d7;
                border-radius: 5px;
                margin-bottom: 10px;
                padding: 15px;
                h4 {
                    font-size: $font-size-22;
                    font-weight: normal;
                    color: #000;
                    text-align: center;
                }
            }
        }
    }
    .right-side {
        width: 50%;
        h4 {
            font-weight: bold;
            font-size: $font-size-24;
            color: #f19922;
            padding-bottom: 35px;
        }
        h5 {
            font-weight: bold;
            font-size: $font-size-20;
            color: $color-white;
            padding-bottom: 35px;
        }
        .title-logo {
            .title {
                width: 40%;
            }
        }
        .section-logo {
            padding-top: 25px;
            .logo {
                width: 21%;
                padding-right: 5px;
            }
        }
    }
    @media screen and (max-width: 1185px) {
        .right-side {
            .title-logo {
                flex-direction: column;
                .title {
                    width: 100%;
                    h5 {
                        padding-bottom: 10px;
                    }
                }
            }
        }

        @media screen and (max-width: 1024px) {
            .right-side {
                .section-logo {
                    .logo {
                        width: 40%;
                        padding-bottom: 20px;
                    }
                }
            }
            @media screen and (max-width: $size-sm-max) {
                .container.flex.items-center {
                    flex-direction: column;
                    .left-side {
                        width: 80%;
                        margin: 0 0 35px 0;
                    }
                    .right-side {
                        width: 100%;
                        .section-logo {
                            padding-top: 50px;
                            .logo {
                                width: 50%;
                                padding-bottom: 35px;
                                .img-responsive {
                                    margin: auto;
                                }
                            }
                        }
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    .container.flex.items-center {
                        .left-side {
                            width: 100%;
                        }
                        .right-side {
                            .section-logo {
                                padding-top: 65px;
                                .logo {
                                    width: 100%;
                                    padding: 0 0 35px 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-footer {
    background: #f19922;
    padding: 25px 0;
    .left-side {
        width: 100%;
        a.tel {
            .icon {
                margin-right: 15px;
                .img-responsive.hover {
                    display: none;
                }
            }
            .text {
                width: 100%;
                h4 {
                    font-weight: normal;
                    font-size: $font-size-16;
                    color: $color-white;
                }
            }
            &:hover {
                img.img-responsive.hover {
                    display: block !important;
                }
                img.img-responsive.defaut {
                    display: none !important;
                }
                h4 {
                    color: #303030;
                }
            }
        }
    }
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

#section-bloc-text.services {
    padding: 75px 0;
    background: url("../images/accueil_section01_bg.jpg") no-repeat;
    background-repeat: repeat;
    background-size: auto;
    h3.title {
        font-weight: bold;
        font-size: $font-size-24;
        color: #f19922;
        text-transform: initial;
        padding-bottom: 35px;
    }
    @media screen and (max-width: 1550px) {
        .container.flex {
            align-items: center;
            .left-side {
                .bloc {
                    .title {
                        h4 {
                            font-size: 1rem;
                            br {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 1185px) {
            .container.flex {
                flex-direction: column;
                .left-side {
                    width: 60%;
                    margin: 0 0 35px 0;
                }
                .right-side {
                    width: 60%;
                }
            }
            @media screen and (max-width: $size-sm-max) {
                .container.flex {
                    .left-side, .right-side {
                        width: 85%;
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    padding: 55px 0;
                    .container.flex {
                        .left-side, .right-side {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* CHAUDIÈRE À VENDRE
*******************************************************************************/

#section-produits {
    padding: 25px 0 75px 0;
    background: url("../images/accueil_section01_bg.jpg") no-repeat;
    background-repeat: repeat;
    background-size: auto;
    .item {
        border-bottom: 2px solid #fff;
        padding: 50px 0;
        .title-box {
            padding-bottom: 35px;
            h3 {
                font-weight: bold;
                font-size: $font-size-30;
                color: #f19922;
                text-transform: initial;
            }
        }
        .images-box {
            a.main-image {
                width: 54%;
                margin-right: 5px;
            } 
            a.small-image {
                margin-right: 5px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .txt-box {
            background: #d7d7d7;
            border-radius: 5px;
            padding: 15px 25px 50px 25px;
            margin-top: 35px;
            p {
                font-family: $font-family-1;
                color: $color-black;
            }
        }
    }
    @media screen and (max-width: $size-md-max) {
        .item {
            .images-box.zoom-gallery.flex.items-baseline {
                flex-wrap: wrap;
                a.main-image, a.small-image {
                    width: 30%;
                    margin-bottom: 5px;
                }
            }
            .txt-box {
                margin-top: 25px;
            }
        }
        @media screen and (max-width: $size-md-max) {
            .item {
                .images-box.zoom-gallery.flex.items-baseline {
                    flex-wrap: wrap;
                    a.main-image, a.small-image {
                        width: 100%;
                        margin-bottom: 5px;
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* EMPLOIS
*******************************************************************************/

#section-text-emploi {
    padding: 75px 10% 0 10%;
    background: url("../images/accueil_section01_bg.jpg") no-repeat;
    background-repeat: repeat;
    background-size: auto;
    border-bottom: 2px solid #fff;
    .section-text {
        p {
            color: $color-white;
        }
        ul {
            padding-top: 30px;
            li {
                font-weight: 500;
                font-size: $font-size-18;
                text-transform: initial;
                line-height: 1.5;
                padding-bottom: 5px;
                color: $color-white;
            }
        }
    }
    .section-emplois {
        padding-top: 50px;
        .item-poste.emploi {
            padding-right: 50px;
            padding-bottom: 100px;
            width: 50%;
            h4 {
                font-weight: bold;
                font-size: $font-size-30;
                color: #f19922;
                text-transform: initial;
                padding-bottom: 15px;
            }
            p {
                font-size: $font-size-20;
                color: $color-white;
            }
            ul {
                padding-top: 10px;
                padding-bottom: 35px;
                li {
                    font-weight: 500;
                    font-size: $font-size-20;
                    text-transform: initial;
                    line-height: 1.5;
                    padding-bottom: 5px;
                    color: $color-white;
                }
            }
        }
    }
    @media screen and (max-width: 1550px) {
        padding: 75px 5% 0 5%;
        .section-emplois {
            .item-poste.emploi {
                padding-right: 100px;
            }
        }
        @media screen and (max-width: 1215px) {
            .section-emplois {
                .container-fluid.row.flex.flex-wrap {
                    flex-direction: column;
                    .item-poste.emploi {
                        padding-right: 0;
                        width: 100%;
                        padding-bottom: 75px;
                    }
                }
            }
        }
    }
}

section#section-form {
    padding: 75px 0 100px 0;
    background: #303030;
    h3 {
        font-weight: bold;
        font-size: $font-size-30;
        color: #f19922;
        text-transform: initial;
        padding-bottom: 35px;
    }
    .module-formulaire {
        padding: 0 5%;
    }
    @media screen and (max-width: 1550px) {
        padding: 75px 5% 100px 5%;
        @media screen and (max-width: 1024px) {
            padding: 75px 2% 100px 2%;
            .module-formulaire {
                padding: 0;
                .section-btn.flex.justify-right.pt7 {
                    justify-content: initial;
                }
            }
            @media screen and (max-width: $size-xs-max) {
                padding: 50px 0 75px 0;
                .module-formulaire {
                    .section-btn.flex.justify-right.pt7 {
                        flex-direction: column;
                        .buttons {
                            justify-content: center;
                            .btn-submit.mr3 {
                                margin: 0 0 25px 0;
                            }
                        }
                    }
                    form label.filedoc {
                        height: 47.98px;
                    }
                    .btn-files.flex {
                        justify-content: center;

                    }
                }
            }
        }
    }
}

/*******************************************************************************
* DEMANDE DE SOUMISSION
*******************************************************************************/

#section-formulaire {
    padding: 100px 0;
    background: #303030;
    label.filedoc {
        width: 240px;
    }
    .section-btn.flex {
        padding-top: 265px;
    }
    @media screen and (max-width: 1500px) {
        form .form-group.list-checkBox.flex.flex-wrap .container input[type="checkbox"] {
            right: 20%;
        }
        @media screen and (max-width: 1315px) {
            .form-group.list-checkBox.flex.flex-wrap .container input[type="checkbox"] {
                right: 10%;
            }
            @media screen and (max-width: 1100px) {
                .form-group.list-checkBox.flex.flex-wrap {
                    flex-direction: column;
                }
                input[type="checkbox"] {
                    right: -5% !important;
                }
                .section-btn.flex {
                    padding-top: 160px !important;
                }
                @media screen and (max-width: $size-sm-max) {
                    .section-btn.flex {
                        flex-direction: column;
                        align-items: baseline;
                        padding-top: 93px !important;
                        .btn-submit.mr3 {
                            margin: 0 0 10px 0;
                        }
                    }
                    @media screen and (max-width: $size-xs-max) {
                        padding: 65px 0;
                        form .form-group.list-checkBox.flex.flex-wrap .container {
                            width: 100%;
                            input[type="checkbox"] {
                                right: 30% !important;
                            }
                        }
                        .section-btn.flex {
                            align-items: center;
                            padding-top: 35px !important;
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    padding: 100px 0;
    background: #303030;
    .left-side {
        width: 50%;
        margin-right: 5%;
        .bloc {
            .icon {
                width: 8%;
                .img-responsive {
                    margin: auto;
                }
            }
            .text {
                width: 100%;
                h3 {
                    font-weight: 600;
                    font-size: $font-size-22;
                    color: $color-white;
                    text-transform: initial;
                }
                h4 {
                    font-weight: 600;
                    font-size: $font-size-20;
                    color: $color-white;
                }
                h5 {
                    font-weight: normal;
                    font-size: $font-size-18;
                    color: $color-white;
                    padding-bottom: 5px;
                }
            }
        }
        .bloc.text-logo {
            .text {
                width: 40%;
                padding-right: 10px;
                padding-bottom: 25px;
            }
        }
    }
    .right-side {
        width: 50%;
    }
    @media screen and (max-width: 1450px) {
        .left-side {
            .bloc.text-logo {
                .text {
                    width: 46%;
                    h3 br {
                        display: none;
                    }
                }
            }
        }
        @media screen and (max-width: 1359px) {
            .container.flex {
                align-items: center;
                .left-side {
                    .bloc.text-logo {
                        padding-top: 2.5rem;
                        .text {
                            width: 100%;
                        }
                        .logo {
                            width: 100%;
                            padding-bottom: 25px;
                        }
                    }
                }
            }
            @media screen and (max-width: 1150px) {
                .container.flex {
                    flex-direction: column;
                    .left-side {
                        width: 100%;
                        margin: 0 0 35px 0;
                    }
                    .right-side {
                        width: 100%;
                    }
                }
                @media screen and (max-width: 1024px) {
                    .container.flex {
                        .right-side {
                            div#map-canvas {
                                height: 400px !important;
                            }
                        }
                    }
                    @media screen and (max-width: $size-xs-max) {
                        padding: 65px 0;
                        .container.flex {
                            .left-side {
                                .bloc.flex.items-center.pb6 {
                                    flex-direction: column;
                                    .icon {
                                        width: 100%;
                                        margin-bottom: 15px;
                                    }
                                    h3 {
                                        font-size: 1.2rem;
                                        text-align: center;
                                    }
                                }
                                .bloc.time.flex {
                                    .icon {
                                        width: 20%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

div#content {
    h3#firstHeading {
        color: #f19922;
    }
    p a {
        color: #303030;
    }
}






/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1755px) {
   
.slideshow-wrap .caption {
    top: 50%;
    left: 38%;
}
}

@media screen and (max-width: 1600px) {

.slideshow-wrap .caption {
    top: 50%;
    left: 35%;
}   
}

@media screen and (max-width: 1560px) {

nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li div {
    font-size: 0.8rem;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 200px;
    border: none;
}
nav.module-menu .menu-navbar .wrapper.left .logo .img-responsive {
    border: 1px solid #fff;
}
}

@media screen and (max-width: 1400px) {

nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li div {
    font-size: 0.8rem;
}
nav.module-menu .menu-navbar .wrapper.right .section-tel ul li.phone a div {
    font-size: 1.1rem;
}
nav.module-menu .menu-navbar .wrapper.right .section-tel {
    margin-bottom: 20px;
}
.slideshow-wrap .caption a.btn-caption {
    width: 30%;
}
}

@media screen and (max-width: $size-md-max) {
   

}

@media screen and (max-width: 1210px) {
   
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul, nav.module-menu .menu-navbar .wrapper.right .main-menu-top {
    display: none;
}
nav.module-menu .menu-toggle {
    position: absolute;
    right: 0;
}
}

@media screen and (max-width: 1024px) {
    
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 190px;
}
nav.module-menu .menu-navbar {
    height: 130px;
}
nav.module-menu .menu-navbar .wrapper.right .section-rbq {
    display: none;
}
.slideshow-wrap .caption {
    top: 50%;
    left: 35%;
}
.slideshow-wrap .caption a.btn-caption {
    width: 40%;
}
}

@media screen and (max-width: $size-sm-max) {
 
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 180px;
    margin-left: 15px;
}
}

@media screen and (max-width: $size-xs-max) {
 
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 155px;
    margin-left: 10px;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu {
    width: 150%;
}
.slideshow-wrap .caption a.btn-caption {
    width: 70%;
}
nav.module-menu .menu-navbar {
    height: 105px;
}
}

@media screen and (max-width: 360px) {

nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 150px;
}
.slideshow-wrap .caption {
    top: 55%;
    left: 53%;
    width: 100%;
}
.slideshow-wrap .caption a.btn-caption {
    width: 50%;
}
}