.module-header {
    position: relative;

    &--image {}

    &--image-mobile {}

    &--caption {
        position: absolute;
        top: 75%;
        left: 10%;
        width: 100%;
        transform: translateY(-50%);

        &--title {
            width: 100%;
            font-size: $font-size-40;
            font-weight: 500;
            color: $color-white;
            // text-shadow: 0 0 10px $color-black;
        }
        &--subtitle {
            width: 100%;
            text-align: center;
            font-size: 3vw;
            font-weight: 300;
            color: $color-1;
            text-shadow: 0 0 10px $color-black;
        }
        @media screen and (max-width: 1550px) {
            left: 2%;
            @media screen and (max-width: $size-sm-max) {
                top: 82%;
                @media screen and (max-width: $size-xs-max) {
                    left: 6%;
                    top: 85%;
                }
            }
        }
    }
}
